import { useDispatch, useSelector } from "react-redux";
import Modal from "../modal.component";
import Button from "components/Button/button.component";
import { getCommonState } from "state/feature/common/common.slice";
import CustomDropDown from "components/CustomDropdown/custom-dropdown.component";
import { AddServiceModalPresentationProps } from "./add-service-modal.props";
import { isEmptyString, validateNumber } from "shared/methods/utilityFunctions";
import "./add-service-modal.styles.scss";
import FacilityDropdown from "pages/task-management/transition-of-care/facility-dropdown";
import { deletePacTypeItem, getTOCManagementState } from "state/feature/toc-management/toc-management.slice";
import { addService, addText, editService, saveText } from "./add-service-modal.constant";
import { CarePlanServices } from "shared/enums/care-plan-services.enum";
import {
  EMPTY_LOS_ERROR,
  LOS_LIMIT,
  LOS_VISIT_ERROR,
} from "pages/task-management/transition-of-care/constants/index.constant";

const AddServiceModalPresentation = (props: AddServiceModalPresentationProps) => {
  const { modal } = useSelector(getCommonState);
  const {
    closeAddServiceModal,
    handleSubmitOnAdd,
    services,
    addServiceState,
    handleAddServiceStateChange,
    showError,
    isHandleSubmitPressed,
    deletePacTypeEntry,
  } = props;
  const { facilities, allFacilities, tocEditForm, carePlanData, isCustomisedCarePlanEnabled, tocs } =
    useSelector(getTOCManagementState);

  const getFacilities = () => {
    if (modal.isOpenAddServiceModal.isEdit) {
      if (
        tocEditForm &&
        tocEditForm.editTocDetails &&
        tocEditForm?.editTocDetails.longName === CarePlanServices.ACUTE
      ) {
        return facilities;
      }
      return allFacilities;
    } else {
      if (addServiceState.service.name === CarePlanServices.ACUTE) {
        return facilities;
      }
      return allFacilities;
    }
  };

  const getIsLosEnabled = () => {
    const isExpandedToc = tocs.find((toc) => toc.isExpanded === true)!;
    if (modal.isOpenAddServiceModal.isEdit) {
      return !(isCustomisedCarePlanEnabled || isExpandedToc?.isCustomised);
    }
    return false;
  };

  return (
    <Modal isOpen={modal.isOpenAddServiceModal.isOpen} className="center add-service-popup">
      <div className="add-service-container">
        <div className="header">{modal.isOpenAddServiceModal.isEdit ? editService : addService}</div>
        <div className="content">
          <div className="heading">Service</div>
          <CustomDropDown
            placeholder="Select"
            dropDownMenuItems={services
              .map((service) => {
                return {
                  key: service.key,
                  name: service.name,
                };
              })
              .filter(
                (item) =>
                  carePlanData.findIndex(
                    (carePlan) => carePlan.pacTypeName === item.name || carePlan.longName === item.name
                  ) === -1
              )}
            handleValueChanges={(option: any) => {
              handleAddServiceStateChange("service", { id: option.key, name: option.name });
            }}
            value={{ name: addServiceState.service.name, key: addServiceState.service.id }}
            idFieldName="key"
            dropDownBoxClass="result-dropdown"
            selectionClass="result-selection"
            dropDownContainerClass={`result-dropdown-container`}
            isDisabled={modal.isOpenAddServiceModal.isEdit}
            message="Select Service"
            isError={isHandleSubmitPressed && showError.service}
          />
          <div className="heading">Provider</div>
          <FacilityDropdown
            selectedOptionId={addServiceState.provider.id ? parseInt(addServiceState.provider.id) : -1}
            facilities={getFacilities()}
            isError={isHandleSubmitPressed && showError.provider}
            onChange={(providerId: number, providerName: string) => {
              handleAddServiceStateChange("provider", { id: providerId.toString(), name: providerName });
            }}
            isDisabled={false}
          />
          {isHandleSubmitPressed && showError.provider && <div className="error-message">Select Provider</div>}
          <div className="visits-los">
            <div className="heading">LOS/Visits</div>
            <div className="acute-los">
              <input
                className={`acute-los-input ${isHandleSubmitPressed && showError.los ? "error" : ""}`}
                type="string"
                placeholder="0"
                name="acute-los"
                value={addServiceState.los}
                maxLength={2}
                disabled={getIsLosEnabled()}
                onChange={(e: any) => {
                  if ((validateNumber(e.target.value) || isEmptyString(e.target.value)) && e.target.value !== "0") {
                    handleAddServiceStateChange("los", { id: "", name: e.target.value });
                  }
                }}
                onKeyDown={(e) => {
                  if (["e", ".", "-", "+"].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
              <span>days/visits</span>
            </div>
            {isHandleSubmitPressed && showError.los && isEmptyString(addServiceState.service.name) && (
              <div className="error-message">{EMPTY_LOS_ERROR}</div>
            )}
            {isHandleSubmitPressed && showError.los && !isEmptyString(addServiceState.service.name) && (
              <div className="error-message">
                {LOS_VISIT_ERROR} and {LOS_LIMIT[addServiceState.service.name.toUpperCase()]}
              </div>
            )}
          </div>
          {/* <div className="heading">Admission Date</div>
          <SingleDatePicker
            required
            defaultDate={null}
            minDate={null}
            maxDate={null}
            isDisabled={modal?.isOpenAddServiceModal?.isEdit ? true : false}
            isError={false}
            handleDateChange={(val) => {
              handleAddServiceStateChange("admissionDate", val);
            }}
            popperPlacement="top"
            errorMessage={"Admission date is required"}
          /> */}
        </div>
        <div className="button-section">
          <div></div>
          <div>
            <Button text="Cancel" className="white-button cancel-button" onClick={closeAddServiceModal} />
            <Button
              text={modal.isOpenAddServiceModal.isEdit ? saveText : addText}
              className="green-button reload-button"
              onClick={handleSubmitOnAdd}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddServiceModalPresentation;
